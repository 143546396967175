<template>
  <div>
    <el-card class="box-card">
      <!-- 搜索与添加区域 -->
      <el-row :gutter="10">
        <el-col :span="6"> </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="addDialogVisible = true"
            >添加溯源码</el-button
          >
        </el-col>
      </el-row>
      <!-- 数据列表区域 -->
      <el-table :data="tableData" style="width: 100%">
        <el-table-column label="编号" type="index"></el-table-column>
        <el-table-column label="作物名称" prop="j_zwmc"></el-table-column>
        <el-table-column label="品种" prop="j_pinzhong"></el-table-column>
        <el-table-column label="生产企业" prop="j_scqy"></el-table-column>
        <el-table-column label="生产基地" prop="j_scjd"></el-table-column>
        <el-table-column label="经营企业" prop="j_jyqy"></el-table-column>
        <el-table-column label="经销商" prop="j_jxs"></el-table-column>
        <!-- <el-table-column label="序列号" prop="j_fzjg"></el-table-column> -->
        <el-table-column label="溯源码" prop="sym"></el-table-column>
        <el-table-column label="创建时间" prop="createtime"></el-table-column>
        <el-table-column label="打印溯源码">
          <template slot-scope="scope">
            <el-tooltip effect="dark" content="打印" placement="top-start">
              <el-button
                @click="printsym(scope.row)"
                type="primary"
                icon="el-icon-printer"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
        <!-- <el-table-column label="品种简介" prop="content"></el-table-column> -->
        <!-- <el-table-column label="操作">
         
          <template slot-scope="scope">
              
            <el-tooltip effect="dark" content="修改" placement="top-start">
              <el-button
                @click="editSCInfo(scope.row)"
                type="primary"
                icon="el-icon-s-tools"
              ></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="删除" placement="top-end">
              <el-button
                type="warning"
                @click="delSCInfo(scope.row.bh)"
                icon="el-icon-delete"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column> -->
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        background
        layout="prev, pager, next,total"
        :total="total"
        :page-size="queryInfo.pageSize"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
      <el-dialog
        title="添加溯源码信息"
        :visible.sync="addDialogVisible"
        width="40%"
        @closed="closedialog"
      >
        <!-- 表单区 -->
        <el-form
          size="medium"
          ref="addSCFormInfo"
          :model="addSCFormInfo"
          label-width="120px"
          :rules="rules"
        >
          <!-- 下拉框 -->
          <el-form-item label="作物名称" prop="j_zwmc">
            <el-input v-model="addSCFormInfo.j_zwmc"></el-input>
          </el-form-item>
          <el-form-item label="品种" prop="j_pinzhong">
            <el-select
              v-model="addSCFormInfo.j_pinzhong" @change="getbase"
              placeholder="请选择品种"
            >
              <el-option
                v-for="item in pinzhongList"
                :key="item.id"
                :label="item.j_mingcheng"
                :value="item.j_mingcheng"   
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="生产企业" prop="j_scqy">
            <el-input v-model="addSCFormInfo.j_scqy"></el-input>
          </el-form-item>
          <el-form-item label="生产基地" prop="j_scjd">
            <el-autocomplete class="inline-input" style="width:100%;" v-model="addSCFormInfo.j_scjd" :fetch-suggestions="querySearch" placeholder="请输入内容" @select="handleSelect" ></el-autocomplete>
            <!-- <el-input v-model="addSCFormInfo.j_scjd"></el-input> -->
          </el-form-item>
          <el-form-item label="经营企业" prop="j_jyqy">
            <el-input v-model="addSCFormInfo.j_jyqy"></el-input>
          </el-form-item>
          <el-form-item label="经销商" prop="j_jxs">
            <el-input v-model="addSCFormInfo.j_jxs"></el-input>
          </el-form-item>
          <el-form-item label="溯源码数量" style="width:100%;" prop="num">
            <el-input-number v-model="addSCFormInfo.num" min="1"></el-input-number>
            <!-- <el-input v-model="addSCFormInfo.num"></el-input> -->
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addSCFormSubmit()">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="打印溯源码" :visible.sync="print" width="40%">
        <div id="tip"></div>
        <div style="overflow: hidden" id="sym">
          <div
            class="symContainer"
            id="symContainer"
            style="
                width: 400px;
              height: 210px;
              border: 3px solid #000 !important;
              margin: 0 auto;
              position: relative;
              right: 2px;
            "
          >
            <div>
              <div id="dialog">
                <div
                  class="sym-tit"
                  style="
                    font-size: 12px;
                    text-align: center;
                    font-size: 12px;
                    color: #000;
                  "
                >
                  种子质量追溯与监管服务系统
                </div>
                <ul
                  class="symInfo"
                  style="
                    font-size: 9px;
                   float:right;width:59%
                    text-align: left;
                    margin-left: 0px;
                    margin-top: 10px;
                    padding: 0 0 0 0;
                  "
                >
                  <li
                    style="height: 15px; padding: 10px 0 0 0; list-style: none"
                  >
                    作物名称：{{ symRow.j_zwmc }}
                  </li>
                  <li
                    style="height: 15px; padding: 10px 0 0 0; list-style: none"
                  >
                    品种名称：{{ symRow.j_pinzhong }}
                  </li>
                  <li
                    style="height: 15px; padding: 10px 0 0 0; list-style: none"
                  >
                    生产企业：{{ symRow.j_scqy }}
                  </li>
                  <li
                    style="height: 15px; padding: 10px 0 0 0; list-style: none"
                  >
                    生产基地：{{ symRow.j_scjd }}
                  </li>
                  <li
                    style="height: 15px; padding: 10px 0 0 0; list-style: none"
                  >
                    经营企业：{{ symRow.j_jyqy }}
                  </li>
                  <li
                    style="height: 15px; padding: 10px 0 0 0; list-style: none"
                  >
                    经销商：{{ symRow.j_jxs }}
                  </li>
                  <li
                    style="height: 15px; padding: 10px 0 0 0; list-style: none"
                  >
                    溯源码：{{ symRow.sym }}
                  </li>
                </ul>
              </div>
              <!-- <div class="sym" id="qrcode" ref="qrcode"></div> -->
              <div
                class="sym"
                style="float:left;width:40%;display:flex;align-items:center;"
              >
                <img
                  :src="`https://zz.zz.jiaotu.cc/code?sym=${symRow.sym}`"
                  alt="" width="100px" style="align-items:center;margin:auto;"
                />
              </div>
            </div>
          </div>
          
        </div>
        <div style="margin: 10px auto; width: 400px; ">
            <div style="margin-left: 45px">
              <el-button size="mini" round type="primary" @click="printDf"
                >打印二维码</el-button
              >
              <el-button size="mini" round type="primary" @click="printword"
                >下载word文件</el-button
              >
              <!-- <el-button size="mini" round type="primary"
                >下载TXT文件</el-button
              > -->
            </div>
          </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { getLodop } from "../../../static/LodopFuncs";

export default {
  name: "sym",
  props: {},
  data() {
    return {
      restaurants: [],
      print: false,
      symRow: {},
      queryInfo: {
        token: "",
        query: "",
        // id: "",
        page: 1,
        pageSize: 8,
      },
      total: 0,
      // 列表渲染数据
      tableData: [],
      addDialogVisible: false,
      addSCFormInfo: {
        j_zwmc: "",
        j_pinzhong: "",
        j_sc_id: "",
        j_scqy: "",
        j_scjd: "",
        j_jyqy: "",
        j_jxs: "",
        class: "sc",
        num: 0,
      },
      newSym: {},
      //验证
      rules: {
        j_zwmc: [
          { required: true, message: "请填写作物名称", trigger: "blur" },
        ],
        j_pinzhong: [
          { required: true, message: "请填写品种", trigger: "blur" },
        ],
        j_scqy: [
          { required: true, message: "请填写生产企业", trigger: "blur" },
        ],
        // j_scjd: [
        //   { required: true, message: "请填写生产基地", trigger: "blur" },
        // ],
        j_jyqy: [
          { required: true, message: "请填写经营企业", trigger: "blur" },
        ],
        num: [{ required: true, message: "请填写数量", trigger: "blur" }],
        j_jxs: [{ required: true, message: "请填写经销商", trigger: "blur" }],
      },
      //品种下拉框
      pinzhongList: [],
    };
  },
  created() {
    this.getDataList();
    this.getPinzhongList();
  },
  methods: {
    querySearch(queryString, cb) {
        var restaurants = this.restaurants;
        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        // 调用 callback 返回建议列表的数据
        cb(results);
      },
      closedialog() {
      for (var name in this.addSCFormInfo) {
        this.addSCFormInfo[name] = "";
      }
    },
      createFilter(queryString) {
        return (restaurant) => {
          return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },
       handleSelect(item) {
        console.log(item);
        console.log(this.addSCFormSubmit.j_scjd)
        
        
      },
    printsym(row) {
      this.print = true;
      this.symRow = row;
    },
    async getbase(e){
      console.log(e)
      let sc_id={}
      this.pinzhongList.forEach(row => {
          console.log(row)
          if(row.j_mingcheng==e){
            sc_id={'j_sc_id':row.id};
            console.log(sc_id)
            //  sc_id.j_sc_id=row.id
          }
        });
         const {data:res2}= await this.$http.post('getBase',sc_id);
      this.restaurants=res2.data;
      // const {data:res2}= await this.$http.post('getBase',{'j_sc_id':});
      // this.restaurants=res2.data;
    },
    async getDataList() {
      this.queryInfo.token = this.tokenStr;
      const { data: res } = await this.$http.post("getSymSc", this.queryInfo);
      this.tableData = res.data.row;
      this.total = res.data.total;
      console.log(res.data);
    },
    async getPinzhongList() {
      // this.queryInfo.token = this.tokenStr;
      const { data: res } = await this.$http.post("getPinzhongList", {
        class: "sc",
      });
      this.pinzhongList = res.data;
      
    },
    addSCFormSubmit() {
      this.pinzhongList.map((item) => {
        if (item.j_mingcheng == this.addSCFormInfo.j_pinzhong) {
          this.addSCFormInfo.j_sc_id = item.id;
        }
      });
      this.$refs.addSCFormInfo.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "createSym",
            this.addSCFormInfo
          );
          this.addSCFormInfo = "";
          this.getDataList();
          this.newSym = res.data;
          this.$router.push({
            path: "/company/symprint",
            query: { name: this.newSym,class:'sc' },
          });
        } else {
          console.log("提交失败!!");
          return false;
        }
      });
    },
    editSCInfo(a) {},
    handleCurrentChange(newPage) {
      // console.log("当前页是" + newPage);
      this.queryInfo.page = newPage;
      this.getDataList();
    },
    printword(){
       let content =  document.getElementById('sym').innerHTML
        var FileSaver = require("file-saver");
        let blod =new Blob([content],{type: "text/html;charset=utf-8"})
        FileSaver.saveAs(blod, `溯源码信息.docx`);
    }, 
    //打印二维码
    printDf() {
      let LODOP = getLodop();
      if (!LODOP) {
        document.getElementById("tip").innerHTML =
          "<br><font color='#FF00FF'>打印控件未安装!点击这里<a href='https://ahsy.oss-cn-hangzhou.aliyuncs.com/CLodop_Setup_for_Win32NT.exe' target='_self'>执行安装</a>,安装后请刷新页面。</font>";
        return;
      }
      // LODOP.PRINT(1,600,400,"");
      LODOP.PRINT_INIT("打印溯源码");
      var i;
      // var count = this.symMsg.length;
      // for (i = 1; i <= count; i++) {
        LODOP.NewPage();
        // console.log(this.printStyle);
        LODOP.SET_PRINT_PAGESIZE(1, '7cm', '4cm', "");
        LODOP.ADD_PRINT_RECT(0, 0, "100%", "100%", 0, 1);
        // let id = "printArea" + this.printStyle + i;
        // console.log(document.getElementById(id).innerHTML);
        LODOP.ADD_PRINT_HTM(
          0,
          0,
          "100%",
          "100%",
          document.getElementById('symContainer').innerHTML
        );
        // LODOP.SET_PRINT_MODE("PRINT_PAGE_PERCENT","Full-Page");
        // LODOP.SET_PRINT_MODE("FULL_WIDTH_FOR_OVERFLOW",true);
      // }
      // this.creatPage;
      LODOP.PREVIEW();
    },
  },
};
</script>

<style>
</style>