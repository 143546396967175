<template>
  <div>
      <el-card class="box-card">
     <el-collapse v-model="activeNames" @change="handleChange">
  <el-collapse-item title="API接口说明" name="1">
    <div>请求地址：
      <el-link rel="stylesheet" href="http://zz.zz.jiaotu.cc/ApiSym">http://zz.zz.jiaotu.cc/ApiSym</el-link>
      </div>
    <div>加入一下列表请求参数 </div>
    <div>参数：apikey: 您生成的授权密钥</div>
    <div>请求方式:post</div>
  </el-collapse-item>
</el-collapse>
<el-divider></el-divider>
<el-button type="primary" @click="addDialogVisible=true">增加APi接口</el-button>
<el-table
      :data="tableData"
      style="width: 100%">
      <el-table-column
        prop="j_zwmc"
        label="作物名称"
        width="180">
      </el-table-column>
      <el-table-column
        prop="j_pinzhong"
        label="姓名"
        width="180">
      </el-table-column>
      <el-table-column
        prop="j_pinzhong_id"
        label="品种ID">
      </el-table-column>
      <el-table-column
        prop="class"
        label="追溯码分类">
      </el-table-column>
      <el-table-column
        prop="j_scqy"
        label="生产企业">
      </el-table-column>
      <el-table-column
        prop="j_scjd"
        label="生产基地">
      </el-table-column>
      <el-table-column
        prop="j_jyqy"
        label="经营企业">
      </el-table-column>
      <el-table-column
        prop="j_jxs"
        label="经销商">
      </el-table-column>
      <el-table-column
        prop="apikey"
        label="授权密钥">
      </el-table-column>
      <el-table-column
          fixed="right"
      label="操作"
      width="100">
      <template slot-scope="scope">
        
        <el-button @click="delApi(scope.row.id)" type="danger" >删除</el-button>
      </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        layout="prev, pager, next,total"
        :total="total"
        :page-size="queryInfo.pageSize"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
      新增接口
  <el-dialog
        title="添加溯源码APi信息"
        :visible.sync="addDialogVisible"
        width="40%"
        @closed="closedialog"
      >
        <!-- 表单区 -->
        <el-form
          size="medium"
          ref="addSCFormInfo"
          :model="addSCFormInfo"
          label-width="120px"
          :rules="rules"
        >
          <!-- 下拉框 -->
          <el-form-item label="作物名称" prop="j_zwmc">
            <el-input v-model="addSCFormInfo.j_zwmc"></el-input>
          </el-form-item>
          <el-form-item label="溯源码类别" prop="class">
            <el-select
              v-model="addSCFormInfo.class" @change="getPinzhongList"
              placeholder="溯源码类别"
            >
              <el-option
                
                label="生产溯源码"
                value="sc"   
              ></el-option>
              <el-option
                
                label="销售溯源码"
                value="xs"   
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="品种" prop="j_pinzhong">
            <el-select
              v-model="addSCFormInfo.j_pinzhong" @change="getbase"
              placeholder="请选择品种"
            >
              <el-option
                v-for="item in pinzhongList"
                :key="item.id"
                :label="item.j_mingcheng"
                :value="item.j_mingcheng"   
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="生产企业" prop="j_scqy">
            <el-input v-model="addSCFormInfo.j_scqy"></el-input>
          </el-form-item>
          <el-form-item label="生产基地" prop="j_scjd">
            <el-autocomplete class="inline-input" style="width:100%;" v-model="addSCFormInfo.j_scjd" :fetch-suggestions="querySearch" placeholder="请输入内容" @select="handleSelect" ></el-autocomplete>
            <!-- <el-input v-model="addSCFormInfo.j_scjd"></el-input> -->
          </el-form-item>
          <el-form-item label="经营企业" prop="j_jyqy">
            <el-input v-model="addSCFormInfo.j_jyqy"></el-input>
          </el-form-item>
          <el-form-item label="经销商" prop="j_jxs">
            <el-input v-model="addSCFormInfo.j_jxs"></el-input>
          </el-form-item>
         
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addSCFormSubmit()">确 定</el-button>
        </span>
      </el-dialog>

</el-card>
  </div>
</template>

<script>
export default {
  data(){
    return{
      restaurants:[],
      activeNames: ['1'],
      tableData:[],
      queryInfo: {
        query: "",
        
        page: 1,
        pageSize: 8,
      },
      addDialogVisible: false,
      total: 0,
      addSCFormInfo: {
        j_zwmc: "",
        j_pinzhong_id: "",
        j_pinzhong:"",
        
        j_scqy: "",
        j_scjd: "",
        j_jyqy: "",
        j_jxs: "",
        class: "",
        
      },
      rules: {
        j_zwmc: [
          { required: true, message: "请填写作物名称", trigger: "blur" },
        ],
        j_pinzhong: [
          { required: true, message: "请填写品种", trigger: "blur" },
        ],
        j_scqy: [
          { required: true, message: "请填写生产企业", trigger: "blur" },
        ],
        // j_scjd: [
        //   { required: true, message: "请填写生产基地", trigger: "blur" },
        // ],
        j_jyqy: [
          { required: true, message: "请填写经营企业", trigger: "blur" },
        ],
        num: [{ required: true, message: "请填写数量", trigger: "blur" }],
        j_jxs: [{ required: true, message: "请填写经销商", trigger: "blur" }],
      },
      pinzhongList: [],
    }
  },
  created(){
    this.getTableData()

  },

  methods:{
  async  delApi(id){
        const {data:res} =await this.$http.post('delApi',{id:id})
        this.$message({
          type:'success',
         message :"删除成功"});
        this.getTableData()
    },
    handleChange(e){

    },
     handleSelect(item) {
        console.log(item);
        console.log(this.addSCFormSubmit.j_scjd)
        
        
      },
    async getTableData(){
        const {data:res}= await this.$http.post('getApiList',this.queryInfo)
        this.tableData=res.data.row
        this.total=res.data.total
    },
    querySearch(queryString, cb) {
        var restaurants = this.restaurants;
        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        // 调用 callback 返回建议列表的数据
        cb(results);
      },
      closedialog() {
      for (var name in this.addSCFormInfo) {
        this.addSCFormInfo[name] = "";
      }
    },
      createFilter(queryString) {
        return (restaurant) => {
          return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },
       handleSelect(item) {
        console.log(item);
        console.log(this.addSCFormSubmit.j_scjd)
        
        
      },
      async getbase(e){
      console.log(e)
      let sc_id={}
      this.pinzhongList.forEach(row => {
          console.log(row)
          if(row.j_mingcheng==e){
            sc_id={'j_sc_id':row.id};
            console.log(sc_id)
            //  sc_id.j_sc_id=row.id
          }
        });
         const {data:res2}= await this.$http.post('getBase',sc_id);
      this.restaurants=res2.data;
      // const {data:res2}= await this.$http.post('getBase',{'j_sc_id':});
      // this.restaurants=res2.data;
    },
    async getPinzhongList(item) {
      // this.queryInfo.token = this.tokenStr;
      console.log(item)
      const { data: res } = await this.$http.post("getPinzhongList", {
        class: item,
      });
      this.pinzhongList = res.data;
      
    },
    addSCFormSubmit() {
      this.pinzhongList.map((item) => {
        if (item.j_mingcheng == this.addSCFormInfo.j_pinzhong) {
          this.addSCFormInfo.j_pinzhong_id = item.id;
        }
      });
      this.$refs.addSCFormInfo.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "createApi",
            this.addSCFormInfo
          );
          this.addSCFormInfo = "";
          this.getTableData();
         this.addDialogVisible=false;
          
        } else {

          console.log("提交失败!!");
          return false;
        }
      });
    },
    handleCurrentChange(newPage) {
      // console.log("当前页是" + newPage);
      this.queryInfo.page = newPage;
      this.getDataList();
    },
  }
}
</script>

<style>

</style>