<template>
  <div>
    <el-card>
      <div slot="header" class="clearfix">
        <el-page-header @back="goBack" content="追溯码打印"> </el-page-header>
      </div>
      <el-row :gutter="20">
        <el-button @click="printsym">打印追溯码</el-button>
        <el-button @click="printword">下载word文件</el-button>
        <div style="overflow: hidden" id="word">
          <div
            v-for="(item, index) in symInfo"
            :key="item.symInfo"
            class="symContainer"
            :id="'printArea1' + (index + 1)"
            style="
              width: 400px;
              height: 210px; 
              border: 3px solid #000 !important;
              margin: 0 auto;
              position: relative;
              right: 2px;
            "
          >
            <div>
              <div>
                <div
                  class="sym-tit"
                  style="
                    font-size: 12px;
                    text-align: center;
                    font-size: 12px;
                    color: #000;
                  "
                >
                  种子质量追溯与监管服务系统
                </div>
                <ul
                  class="symInfo"
                  style="
                    font-size: 9px;
                    float:right;width:59%
                    text-align: left;
                    margin-left: 0px;
                    margin-top: 10px;
                    padding: 0 0 0 0;
                  "
                >
                  <li
                    style="height: 15px; padding: 10px 0 0 0; list-style: none"
                  >
                    作物名称：{{ item.j_zwmc }}
                  </li>
                  <li
                    style="height: 15px; padding: 10px 0 0 0; list-style: none"
                  >
                    品种名称：{{ item.j_pinzhong}}
                  </li>
                  <li
                    style="height: 15px; padding: 10px 0 0 0; list-style: none"
                  >
                    生产企业：{{ item.j_scqy }}
                  </li>
                  <li
                    style="height: 15px; padding: 10px 0 0 0; list-style: none"
                  >
                    生产基地：{{ item.j_scjd }}
                  </li>
                  <li
                    style="height: 15px; padding: 10px 0 0 0; list-style: none"
                  >
                    经营企业：{{ item.j_jyqy }}
                  </li>
                  <li
                    style="height: 15px; padding: 10px 0 0 0; list-style: none"
                  >
                    经销商：{{ item.j_jxs }}
                  </li>
                  <li
                    style="height: 15px; padding: 10px 0 0 0; list-style: none"
                  >
                    溯源码：{{ item.sym }}
                  </li>
                </ul>
              </div>
              <!-- <div class="sym" id="qrcode" ref="qrcode"></div> -->
              <div
                class="sym"
                style="float:left;width:40%;display:flex;align-items:center;"
              >
                <img
                  :src="`https://zz.zz.jiaotu.cc/code?sym=${item.sym}`"
                  alt="" width="100px" style="align-items:center;margin-top:10px;"
                />
              </div>
            </div>
          </div>
        </div>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { getLodop } from "../../../static/LodopFuncs";
export default {
  data() {
    return {
      sym: {},
      symInfo:[],
    };
  },
  created() {
    this.sym = this.$route.query;
    console.log(this.sym);
    this.getSym()
  },
  methods: {
    printword(){
       let content =  document.getElementById('word').innerHTML
        var FileSaver = require("file-saver");
        let blod =new Blob([content],{type: "text/html;charset=utf-8"})
        FileSaver.saveAs(blod, `溯源码信息.docx`);
    }, 
   async getSym(){
      const {data:res}= await this.$http.post('getSymInfo',this.sym)
      this.symInfo=res.data
    },
    goBack() {
      this.$router.push("/company/print");
    },
    printsym(){
      let LODOP = getLodop();
      if (!LODOP) {
        document.getElementById("dialog").innerHTML =
          "<br><font color='#FF00FF'>打印控件未安装!点击这里<a href='https://ahsy.oss-cn-hangzhou.aliyuncs.com/CLodop_Setup_for_Win32NT.exe' target='_self'>执行安装</a>,安装后请重启应用程序。</font>";
        return;
      }
      // LODOP.PRINT(1,600,400,"");
      LODOP.PRINT_INIT("打印溯源码");
      var i;
      var count = this.sym.name.length;
      console.log(this.sym)
      for (i = 1; i <= count; i++) {
        LODOP.NewPage();
        // console.log(this.printStyle);
        LODOP.SET_PRINT_PAGESIZE(1, '7cm', '4cm', "");
        LODOP.ADD_PRINT_RECT(0, 0, "100%", "100%", 0, 1);
        let id = "printArea1"  + (i);
        console.log(id);
     
        LODOP.ADD_PRINT_HTM(
          0,
          0,
          "100%",
          "100%",
          document.getElementById(id).innerHTML
        );
      }
      this.creatPage;
      LODOP.PREVIEW();
    }
  },
};
</script>

<style>
</style>
