<template>
  <div>
      <el-card class="box-card">
      <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="生产溯源码管理" name="first">
        <sym :activeName="activeName" :key="first"></sym>
    </el-tab-pane>
    <el-tab-pane label="销售追溯码管理" name="second" ><xssym :key="second"></xssym></el-tab-pane>
    <el-tab-pane label="追溯码api配置管理" name="three" ><symapi :key="three"></symapi></el-tab-pane>

    
  </el-tabs>
      </el-card>
  </div>
</template>

<script>
import sym from "./sym.vue"
import xssym from "./xiaoshousym.vue"
import printsym from './symprint.vue';
import symapi from './symapi.vue';
export default {
components:{
    sym:sym,
    xssym:xssym,
    printsym:printsym,
    symapi:symapi
},
data(){
    return{
        activeName:"first",
        disabled:"true",
        first:"",
        second:"",
        three:"",
    }
},
methods:{
handleClick(tab){
    if(tab.name=="first"){
        this.first=new Date().getTime()
    }else if(this.name=="second"){
        this.second=new Date().getTime()
    }else if(this.name=="three"){
        this.three=new Date().getTime()
    }

        },
}
}
</script>

<style>

</style>